<template>
  <div id="check-tax-service" :class="{ mobile: !$vuetify.breakpoint.smAndUp }">
    <v-container class="fill-height">
      <!-- 상 -->
      <v-row>
        <v-col cols="12" md="4" offset-md="1">
          <div class="top" :class="{ mobile: !$vuetify.breakpoint.smAndUp }">
            <!-- <h2>세무서비스 점검</h2> -->
            <v-btn
              color="white"
              text
              plain
              :ripple="false"
              class="check-tax-service"
            >
              세무서비스 점검
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <!-- 하 -->
      <v-row>
        <v-col cols="12" md="4">
          <div class="bot" :class="{ mobile: !$vuetify.breakpoint.smAndUp }">
            <div class="each">
              <i class="xi-webcam xi-5x each-icon" />
              <h4>누락된 경비<br />알고 계신가요?</h4>
            </div>
          </div>
        </v-col>

        <v-col cols="12" md="4">
          <div class="bot" :class="{ mobile: !$vuetify.breakpoint.smAndUp }">
            <div class="each">
              <i class="xi-calculator xi-5x each-icon" />
              <h4>세무조사에<br />철저히 대응하고<br />계시나요?</h4>
            </div>
          </div>
        </v-col>

        <v-col cols="12" md="4">
          <div class="bot" :class="{ mobile: !$vuetify.breakpoint.smAndUp }">
            <div class="each">
              <i class="xi-garden xi-5x each-icon" />
              <h4>PCI 분석 및<br />자금출처 확보 방안<br />마련하셨나요?</h4>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({})
}
</script>

<style lang="scss" scoped>
#check-tax-service {
  background-image: url('~@/assets/img/layout/bg_02.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  padding: 40px;

  &.mobile {
    height: 100%;
  }

  // 상
  .top {
    h2 {
      font-size: 48px;
      font-weight: 800 !important;
      color: $color-white;
      position: relative;
      width: 315px;
      margin: 0 auto 120px;

      &::after {
        position: absolute;
        left: 0;
        bottom: -4px;
        content: '';
        width: 100%;
        border-width: 2px;
        border-style: solid;
      }
    }

    .check-tax-service {
      font-size: 48px;
      font-weight: 800 !important;
      padding: 0;
      position: relative;
      cursor: default;
      margin-top: 40px;
      // margin: 0 auto 120px;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -16px;
        width: 100%;
        background-color: $color-white;
        height: 4px;
      }
    }

    &.mobile {
      text-align: center;

      h2 {
        font-size: 24px;
        width: 160px;
        margin: 0 auto 20px;
      }

      .check-tax-service {
        font-size: 24px;
        margin-bottom: 20px;

        &::after {
          bottom: -4px;
        }
      }
    }
  }

  // 하
  .bot {
    display: flex;
    justify-content: center;
    // align-items: center;

    .each {
      text-align: center;
      width: 80%;
      height: 300px;
      // padding: 40px 0;

      .each-icon {
        color: $color-white;
        margin-bottom: 24px;
      }

      h4 {
        font-size: 24px;
        color: $color-white;
        line-height: 40px;
      }
    }

    &.mobile {
      .each {
        height: 240px;
        padding: 24px 0;

        .each-icon {
          font-size: 4em;
          margin-bottom: 16px;
        }

        h4 {
          font-size: 20px;
        }
      }
    }
  }
}
</style>
